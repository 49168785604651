
const datefns = require('date-fns');
const dayjs = require('dayjs');
var findOngoingItems = (items) => {
  const ongoingItems = items.filter(item => {
    const expireDate = new Date(item.dttm_expire);
    const currentDate = new Date();
    const assignment_start_date = new Date(item.dttm_assignment);

    if (item.is_ongoing && currentDate >= assignment_start_date) return true;
    if (!item.dttm_expire) return false;

    return currentDate <= expireDate && currentDate >= assignment_start_date;

  })
  return ongoingItems;
}
var filterItemsByDate = (items, date) => {
  const dateIncludedItems = items.filter(item => {


    const itemDate = new Date(item.dttm_assignment);
    const localItemDate = itemDate.toLocaleDateString();
    const localDate = new Date(date).toLocaleDateString()
    ;
    //if no expire date, just compare the assignment date
    if (!item.dttm_expire && !item.is_ongoing) return localItemDate === localDate;

    let expireDate = new Date(item.dttm_expire);
    if (item.is_ongoing){
      expireDate = new Date();
      //set expiredate to the later of the current date and the assignment date
      expireDate = new Date(Math.max(expireDate, itemDate));
    }
    const localExpireDate = expireDate.toLocaleDateString();
    //set the time on date to 0 local time
    date.setHours(0, 0, 0, 0);
    //if (localDate <= localExpireDate && localDate >= localItemDate) return true;
    if (item.task_entry_id == 102){
    }
    if (dayjs(date).startOf('day') <= dayjs(expireDate).startOf('day') && dayjs(date).startOf('day') >= dayjs(itemDate).startOf('day')) return true;

    return false;
  }
  )

  return dateIncludedItems ;
}
function findEmojiById(emojis, id) {
  return emojis.find(emoji => emoji.task_type_id === id);
}

function isCompletedOnDay(task, day){
  let found = false
  for (let i = 0; i < task.task_completions.length; i++) {
      if (datefns.isSameDay(new Date(task.task_completions[i].tc_dttm_completed), day)) {
          found = task.task_completions[i];
          break;
      }
  }
  return found;
}

function daysSinceDate(date, earlyDate){
  date = new Date(date);
  date.setHours(0, 0, 0, 0);
  const now = earlyDate ? earlyDate : new Date();
  now.setHours(0, 0, 0, 0);

  const diffTime = Math.abs(now - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

function daysBetweenDates(date1, date2){
  date1 = new Date(date1);
  date2 = new Date(date2);
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  const diffTime = Math.abs(date1 - date2);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

module.exports = {
  filterItemsByDate,
  findEmojiById,
  findOngoingItems,
  isCompletedOnDay,
  daysSinceDate,
  daysBetweenDates
}