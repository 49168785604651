import { useState, useEffect } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  set
} from "date-fns";
import React from 'react';
import Modal from 'react-modal';
import DayModal from "./DayModal";
import SignInModal from "./SignInModal";
import axios from 'axios';
import { Button } from "@mui/material";
import helpers from './helpers';
import NewTaskTypeModal from "./NewTaskTypeModal";

const Calendar = ({ showDetailsHandle }) => {
  useEffect(() => {
    const fetchTaskTypes = async () => {
      try {
        const response = await axios.get("/api/tasktypes");
        setEmojis(response.data);
      } catch (error) {
      }
    };

    const fetchTaskEntries = async () => {
      try {
        const response = await axios.get("/api/taskentries");
        setTaskEntries(response.data);
      } catch (error) {
      }

    };

    const fetchSignIn = async () => {
      try {
        const response = await axios.get("/api/auth/login");
        setUser(response.data);
      } catch (error) {
      }
    };

    fetchTaskEntries();
    fetchTaskTypes();
    fetchSignIn();
  }, []);

  const [taskEntries, setTaskEntries] = useState([]);
  const [user, setUser] = React.useState({'status': 'not_logged_in'});
  const [emojis, setEmojis] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalDate, setModalDate] = useState(new Date());
  const [signInModalOpen, setSignInModalOpen] = React.useState(false);

  const signOut = async () => {
    try {
      const response = await axios.post("/api/auth/logout");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }
  const changeMonthHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const onDateClickHandle = (day, dayStr) => {
    setSelectedDate(day);
    showDetailsHandle(dayStr);
    setModalDate(day);
    setIsOpen(true);
  };

  var signInOutButton = null;
  if (user.status == "logged_in"){
    signInOutButton = (<Button onClick={() => {signOut()}}>{user.username}</Button>)
  } else {
    signInOutButton = (<Button onClick={() => {setSignInModalOpen(!signInModalOpen)}}>Sign In</Button>)
  }
  const renderHeader = () => {
    let ongoing_tasks = helpers.findOngoingItems(taskEntries);
    let ongoing_emoji_list = [];
    for (let i = 0; i < ongoing_tasks.length; i++) {
      if (emojis && emojis.length > 0) {
        ongoing_emoji_list.push(helpers.findEmojiById(emojis, ongoing_tasks[i].task_type_id).emoji);
      }
    }
    let intro_text = user.is_dom ? "Your sub is currently" : "You are currently"
    let ongoing_task_text = `${intro_text} ${ongoing_emoji_list.join(" ")}`;
    let compiled_status_text = ongoing_emoji_list.length > 0 ? ongoing_task_text : ""
    const dateFormat = "MMM yyyy";
    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          {/* <div className="icon" onClick={() => changeMonthHandle("prev")}>
            prev month
          </div> */}
        </div>
        <div className="col col-center">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end">
          {/* <div className="icon" onClick={() => changeMonthHandle("next")}>next month</div> */}
        </div>
        <div className="col col-start">
          {/* <div className="icon" onClick={() => changeMonthHandle("prev")}>
            prev month
          </div> */}
        </div>
        <div className="col col-center status-text">
          {compiled_status_text}
        </div>
        <div className="col col-end">
          {signInOutButton}
        </div>
      </div>
    );
  };
  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };
  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        var todaysTasks = helpers.filterItemsByDate(taskEntries, day);

        var emoji_div = null
        if (emojis.length > 0 && todaysTasks.length > 0) {
          emoji_div = todaysTasks.map((task) => {
            return (
              <div style={{'display':'flex'}}>
              <span key={task.task_entry_id} className="emojiLeft">{helpers.findEmojiById(emojis, task.task_type_id).emoji}</span>
              <span style={{marginLeft:'auto', fontSize:'85%'}} className="task-status">
                  {task.completion_dttm ? '✔️': ''}
                </span>
              </div>
            )
          })
        }

        days.push(
          <div
            className={`col cell ${
              isSameDay(day, new Date())
                ? "today"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
            onClick={() => {
              const dayStr = format(cloneDay, "ccc dd MMM yy");
              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            <span className="emojiLeftContainer">
            {
              emoji_div
            }
            </span>
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };
  var legend = (
    <div>
        {
          emojis.map((emoji) => (
            <span key={emoji.task_type_id} className="legend-item" onClick={
              (evt) => {
                setEditingTask(emoji);
                setNewTaskTypeModalIsOpen(true);
              }

            }>
              <span className="legend-emoji">{emoji.emoji}</span>
              <span className="legend-label">{emoji.description}</span>
            </span>
          ))
        }
    </div>
  )
  const renderFooter = () => {
    return (
      <div className="header row flex-middle">
        {legend}
        <div className="col col-start">
          <div className="icon" onClick={() => changeWeekHandle("prev")}>
            prev week
          </div>
        </div>
        <div>{currentWeek}</div>
        <div className="col col-end" onClick={() => changeWeekHandle("next")}>
          <div className="icon">next week</div>
        </div>
      </div>
    );
  };

  let subtitle;
  function openModal(evt, date) {
    setIsOpen(true);
    if (date){
      setModalDate(date);
    }
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const renderSignInModal = () => {
    const customStyles = {
      content: {
        top: '10%',
        left: '10%',
        right: '10%',
        bottom: '10%',
        //marginRight: '-1%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //transform: 'translate(-10%, -10%)',
      },
    };

    return (<div>
    <Modal
      isOpen={signInModalOpen}
      onRequestClose={() => {setSignInModalOpen(false)}}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
    >

        {/*<h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>*/}
        <SignInModal/>{/*yyyy*/}
    </Modal>
  </div>)
  }

  const renderModal = () => {
    const customStyles = {
      content: {
        top: '10%',
        left: '10%',
        right: '10%',
        bottom: '10%',
        //marginRight: '-1%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //transform: 'translate(-10%, -10%)',
      },
    };

    return (<div>
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
    >

        {/*<h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>*/}
        <DayModal emojis={emojis} date={modalDate}
        taskEntries={helpers.filterItemsByDate(taskEntries, modalDate)}
        />{/*yyyy*/}
    </Modal>
  </div>)
  }


  /*===== MODAL New Task type =====*/
  const [newTaskTypeModalIsOpen, setNewTaskTypeModalIsOpen] = React.useState(false);
  const [editingTask, setEditingTask] = React.useState(null);

  const renderNTTModal = () => {
    const customStyles = {
      content: {
        top: '20%',
        left: '10%',
        right: '10%',
        bottom: '20%',
        //marginRight: '-1%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //transform: 'translate(-10%, -10%)',
      },
    };

    return (<div>
      <Modal
        isOpen={newTaskTypeModalIsOpen}
        onRequestClose={() => { setNewTaskTypeModalIsOpen(false) }}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
      >
        {/*<h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>*/}
        {/*<span className = "title">Dom: Place a Task</span>*/}
        <NewTaskTypeModal editingTask={editingTask}/>

      </Modal>
    </div>)
  }

  /*====== No Modal =====*/

  return (
    <div className="calendar">
      {renderHeader()}
      <div className="calendar-inner">
        {renderDays()}
        {renderCells()}
      </div>
      {renderFooter()}
      {renderModal()}
      {renderSignInModal()}
      {renderNTTModal()}
    </div>
  );
};

export default Calendar;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
