import "./styles.css";
import "./stylesmonth.css"
import "./daymodal.css"
import { useState } from "react";
import Calendar from "./Calendar";
import CalendarMonth from "./CalendarMonth";
import Details from "./Details";
import React  from 'react';

export default function App() {
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);
  const queryParameters = new URLSearchParams(window.location.search)
  const view = queryParameters.get("view")


  const showDetailsHandle = (dayStr) => {
    setData(dayStr);
    setShowDetails(false);
  };
  let main = null;
  if (view === "week") {
    main = <Calendar showDetailsHandle={showDetailsHandle} />
  } else {
    main = <CalendarMonth showDetailsHandle={showDetailsHandle} />
  }

  return (
    <div className="App">

      {main}
      <br />
      {showDetails && <Details data={data} />}
    </div>
  );
}

/**
 * Follow this tutorial https://medium.com/@moodydev/create-a-custom-calendar-in-react-3df1bfd0b728
 * and customised by TTNT
 * date-fns doc: https://date-fns.org/v2.21.1/docs
 */
