import { useState, useEffect } from "react";
import {
    format,
    subMonths,
    addMonths,
    startOfWeek,
    addDays,
    isSameDay,
    lastDayOfWeek,
    lastDayOfMonth,
    getWeek,
    addWeeks,
    subWeeks,
    startOfMonth,
    set
} from "date-fns";
import React from 'react';
import Modal from 'react-modal';
import DayModal from "./DayModal";
import SignInModal from "./SignInModal";
import axios from 'axios';
import { Button, Badge, LinearProgress, Alert } from "@mui/material";
import helpers from './helpers';
import NewTaskTypeModal from "./NewTaskTypeModal";
import { canIHasNotification } from "./NotificationsHelper";

const Calendar = ({ showDetailsHandle }) => {
    const [reRender, setReRender] = useState(0);

    useEffect(() => {
        const fetchTaskTypes = async () => {
            try {
                const response = await axios.get("/api/tasktypes");
                setEmojis(response.data);
            } catch (error) {
            }
        };

        const fetchTaskEntries = async () => {
            try {
                const response = await axios.get("/api/taskentries");
                setTaskEntries(response.data);
            } catch (error) {
            }

        };

        const fetchSignIn = async () => {
            try {
                const response = await axios.get("/api/auth/login");
                setUser(response.data);
                if (response.data.status == "logged_in") {
                    //request perms
                }
            } catch (error) {
                setSignInModalOpen(true);
                setCanCloseSignInModal(false);
            }
        };

        fetchTaskEntries();
        fetchTaskTypes();
        fetchSignIn();
    }, [reRender]);

    const [canCloseSignInModal, setCanCloseSignInModal] = useState(true);
    const [taskEntries, setTaskEntries] = useState([]);
    const [user, setUser] = React.useState({ 'status': 'not_logged_in' });
    const [emojis, setEmojis] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalDate, setModalDate] = useState(new Date());
    const [signInModalOpen, setSignInModalOpen] = React.useState(false);

    const signOut = async () => {
        try {
            const response = await axios.post("/api/auth/logout");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }
    const changeMonthHandle = (btnType) => {
        if (btnType === "prev") {
            setCurrentMonth(subMonths(currentMonth, 1));
        }
        if (btnType === "next") {
            setCurrentMonth(addMonths(currentMonth, 1));
        }
    };

    const changeWeekHandle = (btnType) => {
        if (btnType === "prev") {
            setCurrentMonth(subMonths(currentMonth, 1));
            setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
        }
        if (btnType === "next") {
            setCurrentMonth(addMonths(currentMonth, 1));
            setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
        }
    };

    const onDateClickHandle = (day, dayStr) => {
        setSelectedDate(day);
        showDetailsHandle(dayStr);
        setModalDate(day);
        setIsOpen(true);
    };

    var signInOutButton = null;
    if (user.status == "logged_in") {
        signInOutButton = (<div>
            <Button onClick={() => { signOut() }}>{user.username}</Button>
        </div>)
    } else {
        signInOutButton = (<Button onClick={() => { setSignInModalOpen(!signInModalOpen) }}>Sign In</Button>)
    }
    const renderHeader = () => {
        let ongoing_tasks = helpers.findOngoingItems(taskEntries);
        let ongoing_emoji_list = [];

        let intro_text = user.is_dom ? "Your partner is currently" : "You are currently"
        let text_lines = [(
            <div>
                {intro_text}
            </div>
        )];
        //text_lines.push(<div>{intro_text}</div>)
        for (let i = 0; i < ongoing_tasks.length; i++) {
            if (emojis && emojis.length > 0) {
                let curr_task = ongoing_tasks[i]
                ongoing_emoji_list.push(helpers.findEmojiById(emojis, curr_task.task_type_id).emoji);
                let currDay = helpers.daysSinceDate(curr_task.dttm_assignment) + 1;
                let totalDays = curr_task.is_ongoing ? "?" : helpers.daysBetweenDates(curr_task.dttm_assignment, curr_task.dttm_expire) + 1;
                let linearProgressValue = curr_task.is_ongoing ? "" : currDay/totalDays*100
                let linearProgressVariant = curr_task.is_ongoing ? "determinate" : "determinate"
                text_lines.push(

                            <span style={{'display':'block', marginBottom:'10px', fontSize:'.75em'}}>
                                {helpers.findEmojiById(emojis, curr_task.task_type_id).emoji} Day {currDay}/{totalDays}
                                <LinearProgress variant={linearProgressVariant} value={linearProgressValue} />
                            </span>

                )


            }
        }
        let invite_div = null
        if (user.invite_code){
            invite_div = (
                <span>
                    <div style={{
                        'display': 'inline-flex',
                        'alignItems': 'center',
                        verticalAlign: 'middle',
                        left: '20px',
                        'height': '100%',
                        'cursor': 'pointer',
                        'color': 'rgb(25, 118, 210)',
                        fontWeight: 500,
                        fontSize: '12px',
                        'marginBottom': '1em'
                    }} onClick={() => {
                        navigator.clipboard.writeText(`Hey, I'd like you to be my partner on Trackr!  https://trackr.comfyne.com/?coupleCode=${user.invite_code}`)
                        setAlertMessage(`Invite link copied to clipboard: https://trackr.comfyne.com/?coupleCode=${user.invite_code}`)
                        setTimeout(() => {
                            setAlertMessage(null)
                        }, 5000)
                    }}>Invite Partner</div>
                </span>
            )
        }

        let ongoing_task_text = `${intro_text} ${text_lines.join(" ")}`;
        let compiled_status_text = ongoing_emoji_list.length > 0 ? ongoing_task_text : ""
        const dateFormat = "MMMM yyyy";
        return (
            <div className="header row flex-middle">
                <div className="col col-start">
                {text_lines.length > 1 ? text_lines : null}
                {invite_div}
                </div>
                <div className="col col-center status-text">
                    {canIHasNotification()}
                    <span>{format(currentMonth, dateFormat)}</span>

                </div>
                <div className="col col-end">

                    {signInOutButton}
                </div>
            </div>
        );
    };
    const renderDays = () => {
        const dateFormat = "EEE";
        const days = [];
        let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {format(addDays(startDate, i), dateFormat)}
                </div>
            );
        }
        return <div className="days row">{days}</div>;
    };

    const renderCells = () => {
        const startMonth = startOfMonth(currentMonth, { weekStartsOn: 1 });
        const startDate = startOfWeek(startMonth, { weekStartsOn: 1 });
        const endDate = lastDayOfMonth(currentMonth, { weekStartsOn: 1 });
        const dateFormat = "d";
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";
        let maxCount = 0;
        let maxCounterDay = startDate;
        while (maxCounterDay <= endDate) {
            let incrementAmount = helpers.filterItemsByDate(taskEntries, maxCounterDay).length;
            maxCount = incrementAmount > maxCount ? incrementAmount : maxCount;
            maxCounterDay = addDays(maxCounterDay, 1);
        }
        maxCount = maxCount > 4 ? maxCount : 4;
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                var todaysTasks = helpers.filterItemsByDate(taskEntries, day);

                var emoji_div = null
                if (emojis.length > 0 && todaysTasks.length > 0) {
                    emoji_div = todaysTasks.map((task) => {
                        let isDone = helpers.isCompletedOnDay(task, day)
                        let leftPad = isDone ? {} : { 'marginLeft': '1em' }
                        return (
                            <div style={{ 'display': 'flex' }}>
                                <span style={{ fontSize: '85%' }} className="task-status">
                                    {isDone ? '✔️' : ''}
                                </span>
                                <span style={leftPad} key={task.task_entry_id} className="emojiLeft">{helpers.findEmojiById(emojis, task.task_type_id).emoji}</span>

                            </div>
                        )
                    })
                }

                days.push(
                    <div
                        style={{
                            'height': 1.5 * maxCount + 'em'
                        }}
                        className={`col cell ${isSameDay(day, new Date())
                                ? "today"
                                : isSameDay(day, selectedDate)
                                    ? "selected"
                                    : ""
                            }`}
                        key={day}
                        onClick={() => {
                            const dayStr = format(cloneDay, "ccc dd MMM yy");
                            onDateClickHandle(cloneDay, dayStr);
                        }}
                    >
                        <span className="emojiLeftContainer">
                            {
                                emoji_div
                            }
                        </span>
                        <span className="number">{formattedDate}</span>
                        <span className="bg">{formattedDate}</span>
                    </div>
                );
                day = addDays(day, 1);
            }

            rows.push(
                <div className="row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    };
    var legend = (
        <div>
            {
                emojis.map((emoji) => (
                    <span key={emoji.task_type_id} className="legend-item" onClick={
                        (evt) => {
                            setEditingTask(emoji);
                            setNewTaskTypeModalIsOpen(true);
                        }

                    }>
                        <span className="legend-emoji">{emoji.emoji}</span>
                        <span className="legend-label">{emoji.description}</span>
                    </span>
                ))
            }
        </div>
    )
    const renderFooter = () => {
        return (
            <div className="header row flex-middle">

                <div className="col col-start">
                    <div className="icon" onClick={() => changeWeekHandle("prev")}>
                        prev month
                    </div>
                </div>
                <div>{legend}</div>
                <div className="col col-end" onClick={() => changeWeekHandle("next")}>
                    <div className="icon">next month</div>
                    <div className="subreddit" style={{
                        marginTop: '20px'
                    }}>Thoughts?  Comments?  <a href="https://reddit.com/r/comfynetrackr">/r/comfynetrackr</a></div>
                </div>
            </div>
        );
    };

    let subtitle;
    function openModal(evt, date) {
        setIsOpen(true);
        if (date) {
            setModalDate(date);
        }
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const renderSignInModal = () => {
        const customStyles = {
            content: {
                top: '10%',
                left: '10%',
                right: '10%',
                bottom: '10%',
                //marginRight: '-1%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                //transform: 'translate(-10%, -10%)',
            },
        };

        return (<div>
            <Modal
                isOpen={signInModalOpen}
                onRequestClose={() => {
                    if (canCloseSignInModal) {
                        setSignInModalOpen(false)
                    }
                }}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
            >

                {/*<h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>*/}
                <SignInModal />{/*yyyy*/}
            </Modal>
        </div>)
    }

    const renderModal = () => {
        const customStyles = {
            content: {
                top: '10%',
                left: '10%',
                right: '10%',
                bottom: '10%',
                //marginRight: '-1%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                //transform: 'translate(-10%, -10%)',
            },
        };

        return (<div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
            >

                {/*<h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>*/}
                <DayModal emojis={emojis} date={modalDate}
                    taskEntries={helpers.filterItemsByDate(taskEntries, modalDate)}
                    reRenderTrigger={setReRender}
                />{/*yyyy*/}
            </Modal>
        </div>)
    }


    /*===== MODAL New Task type =====*/
    const [newTaskTypeModalIsOpen, setNewTaskTypeModalIsOpen] = React.useState(false);
    const [editingTask, setEditingTask] = React.useState(null);
    const [alertType, setAlertType] = React.useState("info");
    const [alertMessage, setAlertMessage] = React.useState(null);
    const renderAlert = () => {
        console.log(alertMessage)
        if (alertMessage != null) {
            return (<Alert severity={alertType}>{alertMessage}</Alert>)
        }
        return null
    }
    const renderNTTModal = () => {
        const customStyles = {
            content: {
                top: '20%',
                left: '10%',
                right: '10%',
                bottom: '20%',
                //marginRight: '-1%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                //transform: 'translate(-10%, -10%)',
            },
        };

        return (<div>
            <Modal
                isOpen={newTaskTypeModalIsOpen}
                onRequestClose={() => { setNewTaskTypeModalIsOpen(false) }}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
            >
                {/*<h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>*/}
                {/*<span className = "title">Dom: Place a Task</span>*/}
                <NewTaskTypeModal editingTask={editingTask} />

            </Modal>
        </div>)
    }

    /*====== No Modal =====*/

    return (
        <div className="calendar-month">
            {renderAlert()}
            {renderHeader()}
            <div className="calendar-inner">
                {renderDays()}
                {renderCells()}
            </div>
            {renderFooter()}
            {renderModal()}
            {renderSignInModal()}
            {renderNTTModal()}

        </div>
    );
};

export default Calendar;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
