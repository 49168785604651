import { useState, useEffect } from "react";
import React from 'react';
import Modal from 'react-modal';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { id } from "date-fns/locale";
import NewTaskTypeModal from "./NewTaskTypeModal";
import DeletionConfirmation from "./DeletionConfirmation";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import { set } from "date-fns";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import helpers from './helpers';
import { format } from "date-fns";

const AddLogModal = ({ date, taskTypes, taskEntry, reRenderTrigger }) => {

  var endOfDay = dayjs(date).hour(23).minute(59).second(59).year(dayjs().get('year'));
  var now = dayjs();
  // see if date is today
  var isToday = dayjs(date).isSame(dayjs(), 'day');
  //set endOfDay to current year
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [notes, setNotes] = React.useState('');
  const [task, setTask] = React.useState('');
  const [task_type_id, setTaskTypeId] = React.useState('');
  const [dttm_assignment, setDttmAssignment] = React.useState(now);
  const [assigned_user, setAssignedUser] = React.useState('SELF');//SELF/PARTNER
  const [dttm_expire, setDttmExpire] = React.useState(null);
  const [completion_notes, setCompletionNotes] = React.useState('');
  const [completion_dttm, setCompletionDttm] = React.useState('');
  const [isEditing, setIsEditing] = React.useState(false);

  const [hasExpiring, setHasExpiring] = React.useState(false); // deadline
  const [hasCompletion, setHasCompletion] = React.useState(false); // multi-days
  const [isOngoing, setIsOngoing] = React.useState(false); // ongoing
  const [isDeletionConfirmationOpen, setIsDeletionConfirmationOpen] = React.useState(false);


  var emojis = {
    '➕': 'Add a new type of task'
  }


  useEffect(() => {
    if (taskEntry) {
      let isCompleted = helpers.isCompletedOnDay(taskEntry, date);
      setTask(taskEntry.task_type_id);
      setIsEditing(true);
      setNotes(taskEntry.notes);
      setTaskTypeId(taskEntry.task_type_id);
      setDttmAssignment(dayjs(taskEntry.dttm_assignment));
      setNotes(taskEntry.notes);
      setDttmExpire(dayjs(taskEntry.dttm_expire));
      setCompletionNotes(isCompleted.tc_completion_notes);
      setCompletionDttm(isCompleted.tc_dttm_completed);
      setHasCompletion(taskEntry.has_completion == 1);
      setIsOngoing(taskEntry.is_ongoing == 1);
      if (taskEntry){
        if (taskEntry.dttm_assignment && new Date(taskEntry.dttm_assignment).getUTCSeconds() == 59) {
          setHasExpiring(false);
        } else {
          setHasExpiring(true);
        }
      }
    } else {
      setNotes('');
      setTaskTypeId('');
      setDttmAssignment(isToday ? dayjs() : endOfDay);
      setAssignedUser('SELF');
      setDttmExpire(endOfDay);
      setCompletionNotes('');
      setCompletionDttm('');
      setIsOngoing(true);
      setHasCompletion(false);
    }
  }, [])

  var deleteTask = () => {
    setIsDeletionConfirmationOpen(true);
  }
  var completeTask = async () => {
    const data = {
      task_type_id: task_type_id,
      state: "completed",
      dttm_completed: date,
      completion_notes: completion_notes
    }

    var route = `/api/taskentries/${taskEntry.task_entry_id}/complete`;
    var method = 'POST';

    await fetch(route, {
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    reRenderTrigger(Math.random());

  }
  var finishOngoing = async (finishDate) => {
    finishDate = dayjs()
    const data = {
      dttm_assignment: dttm_assignment,//.format('YYYY-MM-DD HH:mm:ss'),
      notes: notes,
      dttm_expire: finishDate,//.format('YYYY-MM-DD HH:mm:ss'),
      //completion_notes: completion_notes,
      //completion_dttm: dayjs(),
      is_ongoing: false,
      has_completion: true,
    };
    var route = '/api/taskentries/' + taskEntry.task_entry_id;
    var method = 'PATCH';
    await fetch(route, {
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(result => {
      // Handle the API response here
      //window.location.reload();
      reRenderTrigger(Math.random());
    })
  }
  var submitTask = (isComplete) => {
    const data = {
      task_type_id: task_type_id,
      dttm_assignment: dttm_assignment,//.format('YYYY-MM-DD HH:mm:ss'),
      notes: notes,
      dttm_expire: hasCompletion && !isOngoing ? dttm_expire : null,//.format('YYYY-MM-DD HH:mm:ss'),
      completion_notes: completion_notes,
      completion_dttm: isComplete ? dayjs() : null,
      is_ongoing: isOngoing && findEmojiById(task).is_timeframe > 0,
      has_completion: hasCompletion && findEmojiById(task).is_timeframe > 0,
    };
    var route = taskEntry ? '/api/taskentries/' + taskEntry.task_entry_id : '/api/taskentries';
    var method = taskEntry ? 'PATCH' : 'POST';
    fetch(route, {
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      //.then(response => response.json())
      .then(result => {
        // Handle the API response here
        //window.location.reload();

        reRenderTrigger(Math.random());
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        //window.location.reload();
        reRenderTrigger(Math.random());
      });
  }

  function findEmojiById(id) {
    return taskTypes.find(taskType => taskType.task_type_id === id);
  }
  const handleChange = (event) => {
    if (event.target.value == "ADDNEW") {
      openModal()
    } else {
      setTask(event.target.value);
      setTaskTypeId(event.target.value);
    }

  };

  /*===== MODAL New Task type =====*/
  function openModal(evt, date) {
    setIsOpen(true);
  }

  function afterOpenModal() {
  }

  function closeModal() {
    setIsOpen(false);
  }
  const renderModal = () => {
    const customStyles = {
      content: {
        top: '20%',
        left: '10%',
        right: '10%',
        bottom: '20%',
        //marginRight: '-1%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //transform: 'translate(-10%, -10%)',
      },
    };

    return (<div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
      >
        {/*<h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>*/}
        {/*<span className = "title">Dom: Place a Task</span>*/}
        <NewTaskTypeModal reRenderTrigger={reRenderTrigger}/>

      </Modal>
    </div>)
  }

  /*====== No Modal =====*/
  var header_text = isEditing ? 'Edit Task' : 'Add Task'
  var delete_button = isEditing ? (<Button
    onClick={deleteTask}
    style={{
      alignSelf: "center",
    }}
    color="error"
    variant="contained">Delete</Button>) : null
  var extendable_input = null
  var ongoing_input = null
  if (task) {
    if (findEmojiById(task).is_timeframe > 0) {
    extendable_input = <FormControlLabel
      control={<Switch key="a" onClick={
        () => {
          setHasCompletion(!hasCompletion)
          setIsOngoing(!hasCompletion)
        }}
        checked={hasCompletion}
      ></Switch>}
      label="Multiple Days"/>

      if (hasCompletion) {
        ongoing_input = <FormControlLabel
        control={<Switch key="b" onClick={
          () => {
            setIsOngoing(!isOngoing)
          }}
          checked={isOngoing}
        ></Switch>}
        label="Ongoing"/>

        let end_date_input = null
        if (!isOngoing){
          end_date_input = (
            <span style={{ marginTop: "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={"Keep Task Until"}
                  value={dttm_expire}
                  onChange={setDttmExpire}
                />
                </LocalizationProvider>
            </span>)
        }

        extendable_input =
          (
            <div>
              {extendable_input}
              {ongoing_input}
              {end_date_input}
            </div>
          )
        }
  }
  }




  var deadline_input = null
  deadline_input =
  <FormControlLabel
  control={<Switch onClick={
    () => {
      setHasExpiring(!hasExpiring)
    }}
    checked={hasExpiring}
  ></Switch>}
  label="Specific Deadline"/>
  deadline_input = null

  if (hasExpiring) {
    deadline_input = (
      <div>
        {deadline_input}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="Deadline"
            value={dttm_assignment}
            onChange={setDttmAssignment}
          />
        </LocalizationProvider>
      </div>
    )
  }

  var complete_task_div = null
  if (isEditing) {
    var endOngoing = null;
    if (findEmojiById(task).is_timeframe && isOngoing) {
      endOngoing = (        <Button
        onClick={() => {finishOngoing()}}
        style={{
          marginTop: "10px",
          alignSelf: "center",
        }}
        variant="contained">End Ongoing Task</Button>)
    }
    complete_task_div = (
      <div style={{display:'flex', flexDirection:'column', borderBottom: '1px solid #232323', paddingBottom:'15px'}}>
      <span className="title">Complete Task</span>
      <div style={{ width: "200px", display: 'flex', alignSelf: "center", justifySelf: "start" }}>

      <FormControl fullWidth>
        <TextField
          id="filled-multiline-static"
          label="Completion Notes"
          multiline
          fullWidth
          rows={2}
          onChange={(event) => { setCompletionNotes(event.target.value) }}
          value={completion_notes}
        />
        <Button
              onClick={() => {completeTask()}}
              style={{
                marginTop: "10px",
                alignSelf: "center",
              }}
              variant="contained">Completed {format(date, "MMM dd")}</Button>
              {endOngoing}
        </FormControl>

      </div>
      </div>
    )
  }
  let deletePath =  taskEntry ? '/api/taskentries/' + taskEntry.task_entry_id : '';
  return (
    <div className="alm-container">

      <main className="main-content">
        <div className="main-div">
          {complete_task_div}
          <span className="title">{header_text}</span>
          <div style={{ width: "200px", display: 'flex', alignSelf: "center", justifySelf: "start" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Task</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={task}
                label="Task"
                onChange={handleChange}
              >
                {
                  taskTypes.map((taskType, index) => (
                    <MenuItem key={index} value={taskType.task_type_id}>{taskType.emoji} {taskType.description}</MenuItem>
                  ))

                }
                <MenuItem key={912318} value={"ADDNEW"}>➕ Add a new type of task</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{
            alignSelf: "center",
            width: "60%",
            display: "flex",
            margin: "35px 0 5px 0"
          }}>
            <TextField
              id="filled-multiline-static"
              label="Task Details"
              multiline
              fullWidth
              rows={4}
              onChange={(event) => { setNotes(event.target.value) }}
              value={notes}
            />
          </div>
          {deadline_input}
          {extendable_input}
          <div style={{
            alignSelf: "center",
            display: "flex",
            margin: "30px"
          }}>
            <Button
              onClick={(event) => {submitTask(false)}}
              style={{
                alignSelf: "center",
              }}
              variant="contained">Save</Button>
            {delete_button}

          </div>
        </div>
      </main>

      <footer className="footer">
      </footer>
      {renderModal()}
      <DeletionConfirmation
        deletePath={deletePath}
        isOpen={isDeletionConfirmationOpen}
        closeHandler={() => setIsDeletionConfirmationOpen(false)}
        confirmationText={'Are you sure you want to delete this task?'}
        rerenderHandler={() => {reRenderTrigger(Math.random())}}
      />
    </div>
  );

};
//['💦', '🔒', '😤' , '🔓', '📝', '🚫💦']
export default AddLogModal;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
