import { useEffect, useState } from "react";
import React from 'react';
import Modal from 'react-modal';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { id } from "date-fns/locale";
import { FormControlLabel, Switch, ToggleButton } from "@mui/material";
import DeletionConfirmation from "./DeletionConfirmation";

const NewTaskTypeModal = ({ editingTask, reRenderTrigger }) => {
  const emojiTypes = Object.freeze({
    STANDARD: 'standard',
    ADDNEW: 'addNew'
  });

  const [newTaskEmoji, setNewTaskEmoji] = React.useState('');
  const [newTaskName, setNewTaskName] = React.useState('');
  const [isTimeFrame, setIsTimeFrame] = React.useState(false);

  const [task, setTask] = React.useState('');
  const [deletionConfirmationOpen, setDeletionConfirmationOpen] = React.useState(false);

  useEffect(() => {
    if (editingTask) {
      setNewTaskEmoji(editingTask.emoji);
      setNewTaskName(editingTask.description);
      setIsTimeFrame(editingTask.is_timeframe);
    }
  }, [editingTask]);

  var saveTask = () => {
    const data = {
      emoji: newTaskEmoji,
      name: newTaskName,
      isTimeFrame: isTimeFrame
    };
    var path = editingTask ? '/api/tasktypes/' + editingTask.task_type_id : '/api/tasktypes';
    var method = editingTask ? 'PATCH' : 'POST';

    fetch(path, {
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      //.then(response => response.json())
      .then(result => {
        // Handle the API response here
        //window.location.reload();
        reRenderTrigger(Math.random());
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        //window.location.reload();
        reRenderTrigger(Math.random());
      });
  }

  var deleteTask = () => {
    setDeletionConfirmationOpen(true);
  }

  var handleEmojiChange = (event) => {
    setNewTaskEmoji(event.target.value);
  }

  var handleNameChange = (event) => {
    setNewTaskName(event.target.value);
  }

  var title = "Add a Task Type"
  var deletePath = null;
  if (editingTask) {
    title = "Edit Task Type"
    deletePath = '/api/tasktypes/' + editingTask.task_type_id;
  }
  return (
    <div className="alm-container">
      <DeletionConfirmation
        deletePath={deletePath}
        isOpen={deletionConfirmationOpen}
        closeHandler={() => setDeletionConfirmationOpen(false)}
        confirmationText={`Are you sure you want to delete the task type ${newTaskEmoji} ${newTaskName}?
        \nThis will remove all entries from your calendar that are associated with this task type.`}
      />
      <main className="main-content">
        <div className="main-div">
          <span className="title">{title}</span>
          <TextField
            required
            id="newTaskEmoji"
            label="Emoji"
            placeholder="🥘"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleEmojiChange}
            value={newTaskEmoji}
          />
          <TextField
            required
            id="newTaskName"
            label="Name"
            placeholder="Cook Dinner"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleNameChange}
            value={newTaskName}
          />
          <FormControlLabel
            label="Can this task last for multiple days?"
            control={<Switch
              id="isTimeFrame"
              onChange={() => setIsTimeFrame(!isTimeFrame)}
              value={isTimeFrame}
            />}
          />

          <div style={{
            alignSelf: "center",
            display: "flex",
            margin: "30px"
          }}>
            <Button
              onClick={() => { saveTask() }}
              style={{
                alignSelf: "center",
              }}
              variant="contained">Save</Button>
            <Button
              onClick={() => { deleteTask() }}
              style={{
                alignSelf: "center",
              }}
              color="error"
              variant="contained">Delete</Button>
          </div>
        </div>
      </main>

      <footer className="footer">
      </footer>
    </div>
  );

};
//['💦', '🔒', '😤' , '🔓', '📝', '🚫💦']
export default NewTaskTypeModal;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
