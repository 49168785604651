import { useState } from "react";
import React from 'react';
import Modal from 'react-modal';
import AddLogModal from "./AddLogModal";
import helpers from './helpers';
import { format } from "date-fns";

const DayModal = ({date, emojis, taskEntries, reRenderTrigger}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [taskEntry, setTaskEntry] = React.useState(null);

  /*===== MODAL Add Request =====*/

  function openModal(evt, taskEntry) {
    // if task_entry_id is set, it implies that the modal is being opened for editing
    if (taskEntry){
      setTaskEntry(taskEntry);
    } else {
      setTaskEntry(null);

    }
    setIsOpen(true);
  }

  function afterOpenModal() {
  }

  function closeModal() {
    setIsOpen(false);
  }
  const renderModal = () => {
    const customStyles = {
      content: {
        top: '10%',
        left: '5%',
        right: '5%',
        bottom: '10%',
        //marginRight: '-1%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //transform: 'translate(-10%, -10%)',
      },
    };
    return (<div>
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
    >
        {/*<h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>*/}
        {/*<span className = "title">Dom: Place a Task</span>*/}
        <AddLogModal date={date} taskTypes={emojis} taskEntry={taskEntry}
        reRenderTrigger={ (a) => {
          reRenderTrigger(a)
          setIsOpen(false)
        }}
        />

    </Modal>
  </div>)
  }

    /*====== No Modal =====*/

  return (
    <div className="dm-container">
      <header className="header">
        <h2>{format(date, "cccc MMM dd")}</h2>
      </header>

      <main className="main-content">
        <div className="main-div">
          <span className="title">Tasks</span>
          <span className="taskList">{
            taskEntries.map((taskEntry) => {
              var emoji_entry = helpers.findEmojiById(emojis, taskEntry.task_type_id);
              let isCompleted = helpers.isCompletedOnDay(taskEntry, date);
              let currDay = helpers.daysSinceDate(taskEntry.dttm_assignment, date) + 1;
              let totalDays = taskEntry.is_ongoing ? "?" : helpers.daysBetweenDates(taskEntry.dttm_assignment, taskEntry.dttm_expire) + 1;
              console.log(taskEntry)
              let onGoingText = taskEntry.is_ongoing || taskEntry.dttm_expire ? `[Day ${currDay}/${totalDays}]` : "";
              return(
              <div>
              <span key={taskEntry.task_entry_id} className="task" onClick={(evt) => {openModal(evt, taskEntry)}}>
                <span className={"nameCircle" + " " + taskEntry.created_user_role}>{taskEntry.created_user_name.slice(0,1)}</span>
                <span className="task-emoji">{emoji_entry.emoji}</span>
                <span className="task-label">{emoji_entry.description} {onGoingText}</span>
                <span style={{marginLeft:'auto'}} className="task-status">
                  {isCompleted ? '✔️': ''}
                </span>
              </span>
              <span key={taskEntry.task_entry_id + "notes"} className="task-notes">{taskEntry.notes}</span>
              <span key={taskEntry.task_entry_id + "notes2"} className="task-notes">{isCompleted.tc_completion_notes}</span>
              </div>
              )
          })
          }</span>
          <span className="add-btn" onClick={openModal}>Add a Task +</span>
        </div>
        {/*<div className="main-div">
          <span className="title">Sub Actions</span>
          <span className="add-btn" onClick={openModal}>Add an Action +</span>
  </div>*/}
      </main>
      {/*
      <footer className="footer">
          <span className="title">Legend</span>
          {legend}
      </footer>
*/}
      {renderModal()}
    </div>
  );

};
//['💦', '🔒', '😤' , '🔓', '📝', '🚫💦']

export default DayModal;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
