import React from 'react';
// import ua-parser-js
import UAParser from 'ua-parser-js';

if ('serviceWorker' in navigator && 'PushManager' in window) {
    navigator.serviceWorker.register('/sw.js')
        .then(function (swReg) {
            console.log('Service Worker Registered', swReg);
        })
        .catch(function (error) {
            console.error('Service Worker Error', error);
        });
} else {
    console.warn('Push messaging is not supported');
}

function askPermission() {
    return new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (result) {
            resolve(result);
        });

        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
    }).then(function (permissionResult) {
        if (permissionResult !== 'granted') {
            throw new Error('We were not granted permission.');
        }
    });
}

function subscribeUserToPush() {
    return navigator.serviceWorker.ready
        .then(function (registration) {
            const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array('BO57WQImtcVDkgWcicEcIQ7YpmVLlTPOJ7WKC7Z2VRBH4H_Q4ESJEs1oq-gl9auZmjUfZ1QOVfcNYF2FhY8rbYU')
            };
            return registration.pushManager.subscribe(subscribeOptions);
        })
        .then(function (pushSubscription) {
            console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
            // Send the subscription object to the server
            return sendSubscriptionToServer(pushSubscription);
        }).catch(function (error) {
            console.error('PushSubscription Error', error);
        });;
}

function sendSubscriptionToServer(subscription) {
    var parser = new UAParser();
    var uaObj = parser.getResult();
    var uaString = `${uaObj.device.vendor || ""} ${uaObj.device.model || ""} / ${uaObj.browser.name} / ${uaObj.os.name} ${uaObj.os.version}`;
    var reqData = {
        'subscription': JSON.stringify(subscription),
        'ua': uaString
    }
    return fetch('/api/subscriptions/save', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(reqData)
    })
        .then(function (response) {
            if (!response.ok) {
                throw new Error('Bad status code from server.');
            }
            return response.json();
        })
        .then(function (responseData) {
            if (!(responseData && responseData.success)) {
                throw new Error('Bad response from server.');
            }
        });
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function askAndHandlePermission() {
    askPermission().then(function () {
        // Subscribe the user to push notifications
        console.log('Permission graanted');
        return subscribeUserToPush();
    }).then(function (subscription) {
        console.log('User is subscribed:', subscription);
        // Optionally, send the subscription object to your server here
    }).catch(function (err) {
        console.error('Failed to subscribe the user: ', err);
    });
}

function canIHasNotification() {
    return null;
    return (<div>
        <button onClick={askAndHandlePermission}>TESTING: Notifications</button>
    </div>)
}
export { canIHasNotification as canIHasNotification };