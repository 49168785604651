import { useEffect, useState } from "react";
import React from 'react';
import Modal from 'react-modal';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { id } from "date-fns/locale";
import { FormControlLabel, Switch, ToggleButton } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DeletionConfirmation = ({ deletePath, isOpen, closeHandler, confirmationText, rerenderHandler}) => {
  const emojiTypes = Object.freeze({
    STANDARD: 'standard',
    ADDNEW: 'addNew'
  });

  const [newTaskEmoji, setNewTaskEmoji] = React.useState('');
  const [newTaskName, setNewTaskName] = React.useState('');
  const [isTimeFrame, setIsTimeFrame] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  const [task, setTask] = React.useState('');

  const handleClickOpen = () => {
    //setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    closeHandler()
  };

  useEffect(() => {
  }, []);

  var deleteTask = () => {
    var path = deletePath;
    fetch(path, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(result => {
        // Handle the API response here
        if (rerenderHandler){
          rerenderHandler()
        } else {
          window.location.reload();
        }

      })
      .catch(error => {
        // Handle any errors here
        if (rerenderHandler){
          rerenderHandler()
        } else {
          window.location.reload();
        }
      });
  }

  return (
    <Dialog
    open={isOpen}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">
        Delete
    </DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{whiteSpace:'pre-wrap'}}>
        {confirmationText}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose} autofocus>Nevermind</Button>
        <Button
        color="error"
        onClick={deleteTask}>
        Delete
        </Button>
    </DialogActions>
    </Dialog>
  );

};
export default DeletionConfirmation;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
